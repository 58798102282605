import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from "./router"
Vue.use(VueRouter)
// import VueAwesomeSwiper from 'vue-awesome-swiper'
//  Vue.use(VueAwesomeSwiper)
// import 'swiper/dist/css/swiper.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
