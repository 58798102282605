import VueRouter from "vue-router"
import Index from '../views/Index.vue'
//import {weiXinInner} from "@/utils/common"
// //import Recharge from '../views/Recharge.vue'
// import Recharge from '../views/recharge/index.vue'
// import Electricity from '../views/electricity/index.vue'
// import OrderList from '../views/electricity/orderList.vue'
// import OrderDetail from '../views/electricity/orderDetail'
// import ElectricityQr from '../views/electricity/qr'
// import Order from '../views/Order.vue'
const routes=[
    {name:'/',path:'/',component:Index},
    {name:'recharge',path:'/recharge',component:()=>import('../views/recharge/index.vue')},
    {name:'electricity',path:'/electricity',component:()=>import('../views/electricity/index.vue')},
    {name:'electricity_order_list',path:'/order_list',component:()=>import('../views/electricity/orderList.vue')},
    {name:'electricity_order_detail',path:'/order_detail/:order_id',component:()=>import('../views/electricity/orderDetail')},
    {path:'/electricity_qr',component:()=>import('../views/electricity/qr')},
    {name:'recharge_order',path:'/order',component:()=>import('../views/Order.vue')},
    {path:'/recharge_qr',component:()=>import('../views/recharge/qr')},
]

const router=new VueRouter({
    routes,
    mode:'history'
 })
// router.beforeEach((to, from,next)=>{
//     if((!weiXinInner())&&to.name&&(to.name.indexOf("electricity")>-1)){
//         next({
//             path:"/electricity_qr"
//         })
//     }else if((!weiXinInner())&&to.name&&(to.name.indexOf("recharge")>-1)){
//         next({
//             path:"/recharge_qr"
//         })
//     }else{
//         next()
//     }
//
// })
export default router
