<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import {setRootFontSize} from "@/utils/common"
export default {
  name: 'App',
  components: {

  },
  mounted() {
    setRootFontSize()
  }
}
</script>

<style>
  @import "./assets/css/reset.css";
#app {

}
</style>
