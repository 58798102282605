export const setRootFontSize=function(){
    const pxUnit = 50;
    const designWid = 750;
    const winWid = document.documentElement.clientWidth || document.body.clientWidth;
    const bl = winWid / designWid;
    let htmlObj=document.querySelector('html');
    htmlObj&&(htmlObj.style.fontSize  = (bl * pxUnit) + 'px');
}
export const weiXinInner=()=>{
    let ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
        return true;
    } else {
        return false;
    }
}
export const getOrigin=()=>{
    let origin = location.origin;
    if(origin.indexOf('localhost')>-1) {
        return 'https://wechat.bruceyz.com';
    } else {
        return origin;
    }
}
